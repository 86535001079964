import type { CreatePassthrough, DataRefMeta, DownloadData } from '../../../clients/data/types';
import type CloudStorageItem from '../../../clients/storage-item/cloud-storage-item';
import DataClient from '../../../clients/data';
import LoggerClient from '../../../clients/logger';
import StorageItemClient from '../../../clients/storage-item';

class DataService {
  #logger = LoggerClient.createNamedLogger('DataService');

  /**
   * Gets a StorageItem from a DataRef.
   * @param dataRefId - The base64 encoded DataRef string.
   * @returns - The StorageItem.
   */
  async #getDataRefStorageItem(dataRefId: string): Promise<CloudStorageItem> {
    const dataRef = DataClient.decodeDataRef(dataRefId);
    const storageItem = await StorageItemClient.createItemFromConnection(dataRef.connection);
    return storageItem;
  }

  /**
   * Get's metadata from the underlying item referenced by the DataRef.
   * @param dataRefId
   * @returns
   */
  async meta(dataRefId: string): Promise<DataRefMeta> {
    try {
      const storageItem = await this.#getDataRefStorageItem(dataRefId);
      return {
        name: storageItem.name,
      };
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Downloads the content of a DataRef.
   * @param {string} dataRefId - The base64 encoded DataRef string.
   * @returns {Promise<DownloadedDataRef>} The downloaded DataRef.
   */
  async download(dataRefId: string): Promise<DownloadData> {
    const storageItem = await this.#getDataRefStorageItem(dataRefId);
    const content = await storageItem.download();
    const updatedDataRefId = DataClient.updateDataRef(dataRefId);
    const meta = {
      name: storageItem.name,
    };
    return {
      meta,
      content,
      dataRefId: updatedDataRefId,
    };
  }

  /**
   * Creates a new Strand from a DataRef.
   * @param {string} dataRefId - The base64 encoded DataRef string.
   * @returns {Promise<CreatedStrand>} The created Strand.
   * @throws Will throw an error if the Strand creation fails.
   */
  async createPassthrough(dataRefId: string): Promise<CreatePassthrough> {
    try {
      const storageItem = await this.#getDataRefStorageItem(dataRefId);
      const passthrough = await storageItem.createPassthroughItem();
      const updatedDataRefId = DataClient.updateDataRef(dataRefId);
      const meta = {
        name: storageItem.name,
      };
      return {
        meta,
        passthroughId: passthrough.projectId,
        dataRefId    : updatedDataRefId,
      };
    } catch (error) {
      this.#logger.error(`Unable to get strandId from DataRef with dataRefId, ${dataRefId}`);
      throw error;
    }
  }
}

export default DataService;
