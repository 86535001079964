import type { CreatePlaneArgsV1 } from './types';
import type { Plane, PlaneState } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { PLANE_HEALTH_TYPES, PLANE_STATUS_TYPES, PLANE_VARIANTS } from '../constants';
import { PlaneError } from '../error';
import { createPlaneSchemaV1 } from './schema';

export const createPlaneV1 = {
  reducer: (state: PlaneState, action: PayloadAction<Plane>) => {
    state[action.payload.id] = action.payload;
  },
  prepare: (plane: CreatePlaneArgsV1) => {
    const id = plane?.id || uuid();
    const createdDate = new Date().toISOString();
    const payload: Plane = {
      id,
      module         : plane.module,
      name           : plane.name,
      extension      : plane.extension,
      icon           : plane.icon,
      state          : plane.state || {},
      cloudStorageId : plane.cloudStorageId,
      createdDate,
      lastUpdatedDate: createdDate,
      health         : PLANE_HEALTH_TYPES.HEALTHY,
      status         : PLANE_STATUS_TYPES.LOADING,
      variant        : PLANE_VARIANTS.PERSISTENT,
      dirty          : plane.dirty || false,
    };
    const { valid, errors } = createPlaneSchemaV1.validate(payload);
    if (!valid) {
      payload.status = PLANE_STATUS_TYPES.ERROR;
      payload.error = PlaneError.FromUnknownError(new Error(errors.map(e => e.message).join(', ')));
    }
    return { payload };
  },
};
