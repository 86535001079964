import type { CloudStorageItemConnection } from './types';
import type {
  CloudStorageProjectItem,
  GetItemByIdOptions,
  InterceptedOpenState,
  StorageProviderItem,
  StorageProviderKey,
} from '@mtb/cloud-storage/types';
import {
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  CloudStorage,
  CloudStorageApi,
  getNameParts,
  useCloudStorageProject,
  useNewCloudStorageProject,
} from '@mtb/cloud-storage';
import config from '../../config';

/**
 * CloudStorageClient is a wrapper around the CloudStorage npm module to centralize the
 * interactions between core and the cloud storage module.
 */
class CloudStorageClient {
  /**
   * Gets the name parts from the given name.
   * @param name - The name to get the parts from.
   * @returns The name parts.
   */
  getNameParts(name: string): ReturnType<typeof getNameParts> {
    return getNameParts(name);
  }

  /**
   * Verifies the given item before opening it.
   * @param item - The item to verify before opening.
   * @returns The verification result.
   */
  verifyBeforeOpen(connection: CloudStorageItemConnection): boolean {
    if (!connection) {
      throw new Error('Failed to verify before open: item is required.');
    }

    if (config.feature_flag_cs_store_v2) {
      return CloudStorageApi.Project.verifyBeforeOpen({
        type   : connection.type as StorageProviderKey,
        itemId : connection.id,
        driveId: connection.driveId,
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Ignore the type error since the connection object is not a StorageProviderItem
    // but really `verifyBeforeOpen` just needs the `id` from the connection object to perform it's check.
    // TODO: Fix the type to be correct on the CloudStorage side.
    return CloudStorage.verifyBeforeOpen(connection);
  }

  /**
   * Gets the cloud storage project with the given ID.
   * @param id - The ID of the cloud storage project to get.
   * @param driveId - The drive ID of the cloud storage project to get.
   * @param options - The options to get the cloud storage project with.
   * @returns The cloud storage project with the given ID.
   */
  async getItemById(
    connection: CloudStorageItemConnection,
    options?: GetItemByIdOptions,
  ): Promise<StorageProviderItem> {
    if (!connection) {
      throw new Error('Failed to get item by ID: connection is required.');
    }

    // TODO: Replace this with the new `getItem` method once it's available from the cloud-storage side.
    if (config.feature_flag_cs_store_v2) {
      const item = await CloudStorageApi.Provider.getItem({
        type   : connection.type as StorageProviderKey,
        itemId : connection.id,
        driveId: connection.driveId,
      }, options);
      if (!item) {
        throw new Error('Failed to get item by ID: an item could not be found.');
      }
      return item;
    }

    // cloud-storage's driveId and options arguments to be optional.
    const item = await CloudStorage.getItemById(connection.id, connection.driveId, options);
    if (!item) {
      throw new Error('Failed to get item by ID: an item could not be found.');
    }
    return item;
  }

  /**
   * Creates a new project with the given file
   * @param file - The file to create the project from.
   * @returns
   */
  async createProject(file: File): Promise<CloudStorageProjectItem> {
    if (!file) {
      throw new Error('Failed to create project: file is required.');
    }

    if (config.feature_flag_cs_store_v2) {
      const project = await CloudStorageApi.Project.createProject(file);
      return {
        projectId  : project.id,
        name       : project.name,
        displayName: project.displayName ?? '',
        extension  : project.extension ?? '',
      };
    }

    const project = await CloudStorage.createProject(file);
    if (!project) {
      throw new Error('Failed to create project.');
    }

    return {
      projectId  : project.projectId,
      name       : project.name,
      displayName: project.displayName,
      extension  : project.extension,
    };
  }

  /**
   * Creates a project using the given cloud storage item.
   * @param item - The cloud storage item to create the project from.
   * @param overrideLock - Whether to override the lock on the item.
   * @returns The created project.
   */
  async openProject(
    connection: CloudStorageItemConnection,
    overrideLock?: boolean,
  ): Promise<CloudStorageProjectItem | boolean> {
    const item = await this.getItemById(connection);
    if (config.feature_flag_cs_store_v2) {
      const project = await CloudStorageApi.Project.openProject(item, overrideLock);
      return {
        projectId  : project.id,
        name       : project.name,
        displayName: project.displayName ?? '',
        extension  : project.extension ?? '',
      };
    }
    return await CloudStorage.openProject(item, overrideLock);
  }

  /**
   * Creates a passthrough item from the given file.
   * @param file - The file to create a passthrough item from.
   * @returns The created passthrough item.
   */
  async createPassthroughItem(file: File): ReturnType<typeof CloudStorage.createPassthroughItem> {
    if (!file) {
      throw new Error('Failed to create passthrough item: file is required.');
    }

    if (config.feature_flag_cs_store_v2) {
      const passthrough = await CloudStorageApi.Project.createPassthroughItem(file);
      return {
        projectId  : passthrough.projectId,
        name       : passthrough.name,
        displayName: passthrough.displayName ?? '',
        extension  : passthrough.extension ?? '',
      };
    }

    return await CloudStorage.createPassthroughItem(file);
  }

  /**
   * Opens the given passthrough item.
   * @param storageItem - The passthrough item to open.
   * @returns The opened passthrough item.
   */
  async openPassthroughItem(
    connection: CloudStorageItemConnection,
  ): ReturnType<typeof CloudStorage.openPassthroughItem> {
    const item = await this.getItemById(connection);
    if (config.feature_flag_cs_store_v2) {
      const passthrough = await CloudStorageApi.Project.openPassthroughItem(item);
      return {
        projectId  : passthrough.projectId,
        name       : passthrough.name,
        displayName: passthrough.displayName ?? '',
        extension  : passthrough.extension ?? '',
      };
    }

    return await CloudStorage.openPassthroughItem(item);
  }

  /**
   * Gets the project with the given ID.
   * @param projectId - The project ID to get.
   * @returns The project with the given ID.
   */
  getProjectById(projectId: string): ReturnType<typeof CloudStorage.getProjectById> {
    return config.feature_flag_cs_store_v2
      ? CloudStorageApi.Project.getProject(projectId)
      : CloudStorage.getProjectById(projectId);
  }

  /**
   * Determines whether the project with the given ID is currently unsaved.
   * @param projectId - The project ID to check.
   * @returns Whether the project is currently unsaved.
   */
  isUnsavedProject(projectId: string): boolean {
    if (!projectId) {
      return false;
    }

    const cloudStorageProject = this.getProjectById(projectId);
    const isReadOnlyProject = cloudStorageProject?.cloudStatus === CLOUD_STATUS.READONLY;
    const isProjectAutoSaving = cloudStorageProject?.autoSaveStatus === AUTO_SAVE_STATUS.STARTED;
    return !isReadOnlyProject && !isProjectAutoSaving;
  }

  /**
   * Renames the given storage item with the given name.
   * @param item - The item to rename.
   * @param name - The new name of the item.
   * @returns The renamed item.
   */
  async renameItem(connection: CloudStorageItemConnection, name: string): Promise<string | void> {
    const item = await this.getItemById(connection);
    if (config.feature_flag_cs_store_v2) {
      return await CloudStorageApi.Provider.renameItem({
        type   : connection.type as StorageProviderKey,
        itemId : connection.id,
        driveId: connection.driveId,
      }, name);
    }
    return await CloudStorage.renameItem(item, name);
  }

  /**
   * Duplicates the given item.
   * @param item - The item to duplicate.
   * @returns The duplicated item.
   */
  async duplicateItem(connection: CloudStorageItemConnection): Promise<StorageProviderItem | void> {
    const item = await this.getItemById(connection);
    if (config.feature_flag_cs_store_v2) {
      return await CloudStorageApi.Provider.duplicateItem({
        type   : connection.type as StorageProviderKey,
        itemId : connection.id,
        driveId: connection.driveId,
      });
    }
    return await CloudStorage.duplicateItem(item);
  }

  /**
   * Downloads the contents of the given item and returns a Blob.
   * @param type - The type of storage provider to download the item from.
   * @param id - The ID of the item to download.
   * @param driveId - The drive ID of the item to download.
   * @returns The downloaded item as a Blob.
   */
  async downloadItem(connection: CloudStorageItemConnection): Promise<Blob> {
    if (!config.feature_flag_cs_store_v2) {
      throw new Error('Failed to download item: downloadItem is not supported in the current environment.');
    }
    return await CloudStorageApi.Provider.downloadItem(connection.type, connection.id, connection.driveId);
  }

  /**
   * Gets the storage item from cloud storage using the state object.
   * @param state - The state object to get the storage item from.
   * @returns The storage item from cloud storage.
   */
  async getStorageItemFromState(state: InterceptedOpenState): Promise<StorageProviderItem> {
    if (!state) {
      throw new Error('Failed to get item from state: state is required.');
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Ignore the type error until the types are exported on the cloud-storage side.
    // It says that `ProviderService` is missing `getItemFromInterceptedState` but it's there.
    const item = CloudStorageApi.Provider.getItemFromInterceptedState(state);
    if (!item) {
      throw new Error('Failed to get item from state: an item could not be found.');
    }
    return item;
  }

  async healthCheckProject(projectId: string): Promise<boolean> {
    try {
      const check = config.feature_flag_cs_store_v2
        ? CloudStorageApi.Project.syncProjectInfo(projectId)
        : CloudStorage.healthCheckProject(projectId);
      return await check;
    } catch {
      return false;
    }
  }

  async closeProject(projectId: string): Promise<boolean> {
    if (config.feature_flag_cs_store_v2) {
      CloudStorageApi.Project.closeProject(projectId);
      return true;
    }
    return await CloudStorage.closeProject(projectId);
  }

  async recoverProject(projectId: string): Promise<CloudStorageProjectItem | null> {
    if (config.feature_flag_cs_store_v2) {
      const project = await CloudStorageApi.Project.recoverProject(projectId);
      return {
        projectId  : project.id,
        name       : project.name,
        displayName: project.displayName ?? '',
        extension  : project.extension ?? '',
      };
    }
    const recovered = await CloudStorage.recoverProject(projectId);
    return recovered ? recovered : null;
  }

  async getIsProjectRecoverable(projectId: string): Promise<boolean> {
    return config.feature_flag_cs_store_v2
      ? await CloudStorageApi.Project.isRecoverable(projectId)
      : await CloudStorage.getIsProjectRecoverable(projectId);
  }

  async renameProject(projectId: string, name: string): Promise<boolean> {
    return config.feature_flag_cs_store_v2
      ? CloudStorageApi.Project.renameProject(projectId, name)
      : CloudStorage.renameProject(projectId, name);
  }

  /**
   * Hook to get the cloud storage project with the given ID.
   * TODO: Define the return type of these functions using exported types from cloud-storage
   * after types are exported on the cloud-storage side.
   * @param projectId - The ID of the cloud storage project to get.
   * @returns The cloud storage project with the given ID.
   */
  useProject(
    projectId: string,
  ): ReturnType<typeof useCloudStorageProject> | ReturnType<typeof useNewCloudStorageProject> {
    return config.feature_flag_cs_store_v2 ? useNewCloudStorageProject(projectId) : useCloudStorageProject(projectId);
  }
}

const cloudStorageClient = new CloudStorageClient();

export default cloudStorageClient;
