import type { OnOpenReason } from '../constants';
import type { StorageProviderItem } from '@';
import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { Stack } from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../constants';
import { useSessionStorage } from '../../../hooks';
import projectService from '../../../services/projects';
import { useBreadcrumbs, useCloudExplorer } from '../hooks';
import { CloudExplorerTableContext } from './context';

type CloudExplorerTableContainerProps = PropsWithChildren<object>;
export const CloudExplorerTableContainer = ({ children }: CloudExplorerTableContainerProps) => {
  const { id, category, defaultFilter, disableCache, onOpen, onError } = useCloudExplorer();

  const { breadcrumbs, setBreadcrumbs, pushBreadcrumb, popBreadcrumb } = useBreadcrumbs();

  const [filter, setFilter] = useSessionStorage({
    noun        : 'filter',
    id,
    initialValue: defaultFilter,
    disableCache,
  });

  const handleOpenItem = useCallback(
    async (item: StorageProviderItem, reason?: OnOpenReason) => {
      if (item.folder) {
        // TODO: test me
        pushBreadcrumb(item);
        return;
      }

      try {
        if (!projectService.verifyBeforeOpen({
          type   : item.type,
          itemId : item.id,
          driveId: item.driveId,
        })) {
          return false;
        }
        await onOpen?.(item, reason);
      } catch (error) {
        console.error(error);
        onError?.(error);
      }
    },
    [onError, onOpen, pushBreadcrumb],
  );

  // TODO: Move to table
  // Allows the parent component to hook into the current state of the table through onChange
  // useEffect(() => {
  //   onChange?.({
  //     selected,
  //     folder,
  //     category,
  //   });
  // }, [onChange, selected, folder, category]);


  const contextValue = useMemo(
    () => ({
      filter,
      setFilter,
      openItem: handleOpenItem,
      breadcrumbs,
      setBreadcrumbs,
      pushBreadcrumb,
      popBreadcrumb,
    }),
    [
      filter,
      setFilter,
      handleOpenItem,
      breadcrumbs,
      setBreadcrumbs,
      pushBreadcrumb,
      popBreadcrumb,
    ],
  );

  const showTable = useMemo(
    () => category !== CLOUD_STORAGE_CATEGORIES.NONE,
    [category],
  );

  if (!showTable) {
    return null;
  }

  return (
    <CloudExplorerTableContext.Provider value={contextValue}>
      <Stack
        gap={2}
        height="100%"
        width="100%">
        {children}
      </Stack>
    </CloudExplorerTableContext.Provider>
  );
};
