import type { DataRef } from './types';
import LoggerClient from '../logger';

class DataClient {
  #version = '1.0.0';
  #logger = LoggerClient.createNamedLogger('DataClient');

  /**
   * Encodes a DataRef object into a base64 string.
   * @param {DataRef} dataRef - The DataRef object to encode.
   * @returns {string} The encoded DataRef as a base64 string.
   * @throws Will throw an error if encoding fails.
   */
  encodeDataRef(dataRef: Partial<DataRef>): string {
    try {
      return btoa(JSON.stringify({
        ...dataRef,
        version: this.#version,
      }));
    } catch (error) {
      this.#logger.error(error);
      throw Error('Failed to encode dataRef.');
    }
  }

  /**
   * Decodes a base64 string into a DataRef object.
   * @param {string} dataRefId - The base64 encoded DataRef string.
   * @returns {DataRef} The decoded DataRef object.
   * @throws Will throw an error if decoding fails.
   */
  decodeDataRef(dataRefId: string): DataRef {
    try {
      return JSON.parse(atob(dataRefId));
    } catch (error) {
      this.#logger.error(error);
      throw Error('Failed to decode dataRef.');
    }
  }

  /**
   * Decodes the DataRef object, updates it, and then encodes it back into a base64 string.
   * @param dataRefId - The base64 encoded DataRef string.
   * @param update - The partial DataRef object to update the DataRef with.
   * @returns The updated DataRef as a base64 string.
   */
  updateDataRef(dataRefId: string, update: Partial<DataRef> = {}): string {
    const dataRef = this.decodeDataRef(dataRefId);
    const updatedDataRef = {
      ...dataRef,
      ...update,
      version: this.#version,
    };
    return this.encodeDataRef(updatedDataRef);
  }

  /**
   * Creates a new DataRef using the provided connection.
   * @param {DataRef['connection']} connection - The connection to associate with the created DataRef.
   * @returns {string} The encoded DataRef as a base64 string.
   * @throws Will throw an error if the DataRef creation fails.
   */
  createDataRef(connection: DataRef['connection']): string | undefined {
    if (!connection || !connection.id || !connection.type) {
      return undefined;
    }

    return this.encodeDataRef({
      connection,
    });
  }
}

const dataClient = new DataClient();
export default dataClient;
