import type { StorageProviderItem } from '@';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  SearchIcon,
  TextField,
} from '@mtb/ui';
import { debounce } from '@mtb/utilities';
import ProviderClient from '../../../../../clients/provider';
import { useTranslation } from '../../../../../services/i18n';
import { filterItems } from '../../../../../utils';
import { useCloudExplorer, useCloudExplorerTable } from '../../../hooks';

type FileSearchInputProps = {
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [other: string]: any;
};
const FileSearchInput = ({ loading, ...other }: FileSearchInputProps) => {
  const [t] = useTranslation();
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
    <TextField
      sx={{ backgroundColor: 'background.50' }}
      {...other}
      InputProps={{
        ...other.InputProps,
        placeholder   : t('search.placeholder'),
        startAdornment: (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
          <InputAdornment placement="start">
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {loading
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
              ? <CircularProgress
                color="inherit"
                size={20} />
              : null
            }
            {other.InputProps.endAdornment}
          </>
        ),
      }} />
  );
};

export const CloudExplorerFileSearch = () => {
  const [t] = useTranslation();
  const { provider } = useCloudExplorer();
  const { filter, openItem } = useCloudExplorerTable();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<StorageProviderItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useMemo(
    () => debounce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: string, callback: (results: any) => void) => {
        ProviderClient.searchItem(provider, value, 30).then(callback);
      },
      250,
    ),
    [provider],
  );

  useEffect(() => {
    let isMounted = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    setIsLoading(true);
    fetch(inputValue, (results) => {
      if (isMounted) {
        let newOptions: StorageProviderItem[] = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [inputValue, fetch]);

  const handleOnChange = useCallback(
    async (_: unknown, value: StorageProviderItem) => {
      if (!value?.id) {
        return;
      }
      const item = await ProviderClient.getItemById(
        provider,
        value?.id,
        value?.driveId,
        { cache: false },
      );
      if (!item) {
        return;
      }
      await openItem?.(item);
    },
    [openItem, provider],
  );

  const noOptionsText = useMemo(
    () => (isLoading ? t('connection.loading') : t('search.noResults')),
    [isLoading, t],
  );


  const isOptionEqualToValue = useCallback(
    (option: StorageProviderItem, value: StorageProviderItem) => option?.id === value?.id,
    [],
  );

  const getOptionLabel = useCallback((option: StorageProviderItem) => option?.name ?? '', []);

  const handleOnInputChange = useCallback(
    (_: unknown, newInputValue: string) => setInputValue(newInputValue),
    [],
  );

  const handleFilterOptions = useCallback(
    (options: StorageProviderItem[]) => {
      const filteredOptions = filterItems(options, filter);
      return filteredOptions;
    },
    [filter],
  );

  return (
    <Autocomplete
      autoComplete
      clearOnBlur
      clearOnEscape
      // @ts-expect-error - Type '(options: StorageProviderItem[]) => StorageProviderItem[]' is not assignable to type '(options: unknown[], state: {}) => unknown[]'.
      filterOptions={handleFilterOptions}
      // @ts-expect-error - Type '(option: StorageProviderItem) => string' is not assignable to type '(option: unknown) => string'.
      getOptionLabel={getOptionLabel}
      // @ts-expect-error - Type '(option: StorageProviderItem, value: StorageProviderItem) => boolean' is not assignable to type '(option: unknown, value: unknown) => boolean'.
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={noOptionsText}
      options={options}
      renderInput={FileSearchInput}
      sx={{ width: '65%' }}
      // @ts-expect-error - Type '(_: unknown, value: StorageProviderItem) => Promise<void>' is not assignable to type '(event: SyntheticEvent<Element, Event>, value: unknown, reason: "clear" | "createOption" | "selectOption" | "removeOption" | "blur", details?: { option: unknown; } | undefined) => void'.
      onChange={handleOnChange}
      onInputChange={handleOnInputChange} />
  );
};
