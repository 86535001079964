import type { CreateItemOptions } from './types';
import type { CloudStorageItemConnection } from '../cloud-storage/types';
import cloudStorageClient from '../cloud-storage';
import CloudStorageItem from './cloud-storage-item';
import FileStorageItem from './file-storage-item';

class StorageItemClient {
  async createItemFromConnection(connection: CloudStorageItemConnection) {
    if (!connection) {
      throw new Error('Invalid connection.');
    }

    const storageItem = await cloudStorageClient.getItemById(connection);
    if (!storageItem) {
      throw new Error('Failed to get storage item from connection.');
    }

    return new CloudStorageItem(storageItem);
  }

  /**
   * Create a storage item from a file or cloud storage item.
   * @param options - The options to create the storage item with.
   * @returns - The created storage item.s
   */
  createItem(options: CreateItemOptions = {}) {
    if (!!options.storageItem) {
      return new CloudStorageItem(options.storageItem);
    }

    const fileName = options.name || options.file?.name || '';
    const fileContent = options.file ? [options.file] : [];
    const fileOptions = options.file?.type ? { type: options.file.type } : {};
    const file = new File(fileContent, fileName, fileOptions);
    return new FileStorageItem(file);
  }
}

const storageItem = new StorageItemClient();
export default storageItem;
