import type { ConnectionInfo } from '../../services/projects/types';
import type {
  ConnectionService,
  GetItemByIdOptions,
  InsightsSettingsResponse,
  InterceptedAuthResponse,
  InterceptedOpenState,
  StorageProviderCheckoutUser,
  StorageProviderDriveId,
  StorageProviderItem,
  StorageProviderItemConnection,
  StorageProviderItemId,
  StorageProviderKey,
  StorageProviderUser,
} from '@';
import { STORAGE_PROVIDER_KEYS } from '../../constants';
import { gDriveService, msGraphService } from '../../providers';
import { getIsItemRoot, getProviderServiceByType } from '../../utils';

const getProvider = (
  type: StorageProviderKey,
): ConnectionService<StorageProviderKey> => {
  const provider = getProviderServiceByType(type);
  if (!provider) {
    throw new Error(`Provider ${type} not found`);
  }
  return provider;
};

type UnvalidatedConnection = {
  type: StorageProviderKey;
  itemId?: StorageProviderItemId;
  driveId?: StorageProviderDriveId;
};

function extractConnection(connection: UnvalidatedConnection): ConnectionInfo {
  if (!connection) {
    throw new Error('Connection is undefined.');
  }

  if (connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
    throw new Error('Recieved a local connection.');
  }

  if (!connection.itemId) {
    throw new Error('Item ID is undefined.');
  }

  if (
    connection.type === STORAGE_PROVIDER_KEYS.ONE_DRIVE &&
    !connection.driveId
  ) {
    throw new Error('Drive ID is undefined.');
  }

  return {
    type   : connection.type,
    itemId : connection.itemId,
    driveId: connection.driveId ?? '',
  };
}

class ProviderClient {
  /**
   * Logs in to the provider.
   * @param type - The provider type.
   * @returns - A promise that resolves when the login is complete.
   */
  login(type: StorageProviderKey): Promise<void> {
    return getProvider(type)?.login();
  }

  /**
   * Logs out of the provider.
   * @param type - The provider type.
   * @returns - A promise that resolves when the logout is complete.
   */
  logout(type: StorageProviderKey): Promise<void> {
    return getProvider(type)?.logout();
  }

  /**
   * Fetches the account information for the current provider.
   * @param type - The provider type.
   * @param response - The response from the provider.
   * @returns - The account information.
   */
  getAccount(
    type: StorageProviderKey,
    response?: InterceptedAuthResponse,
  ): Promise<StorageProviderUser | null> {
    return response
      ? getProvider(type)?.api.getAccount(false, response)
      : getProvider(type)?.api.getAuthedAccount();
  }

  /**
   * Fetches the account picture for the current provider.
   * @param type - The provider type.
   * @returns - A DataURL string of the account picture.
   */
  async getAccountPicture(type: StorageProviderKey): Promise<string> {
    const picture = await getProvider(type)?.api.getAccountPicture();
    if (!picture) {
      return '';
    }

    const reader = new FileReader();

    try {
      return await new Promise((resolve, reject) => {
        reader.onloadend = () => {
          if (typeof reader.result !== 'string') {
            reject();
            return;
          }
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(picture);
      });
    } catch {
      return '';
    }
  }

  /**
   * Fetches the insights settings for the current provider.
   * @param type - The provider type.
   * @returns - The insights settings.
   */
  async getInsightsSettings(type: StorageProviderKey): Promise<InsightsSettingsResponse> {
    const settings = await getProvider(type).api.getInsightsSettings();
    if (!settings) {
      return { isEnabled: false };
    }
    return settings;
  }

  /**
   * Gets the children of a folder from the storage service API.
   * @param folderId - The folder to get the children for.
   * @param signal - A signal used to cancel the request
   */
  getChildren(
    type: StorageProviderKey,
    folder: StorageProviderItem,
    signal: AbortSignal | undefined,
  ): Promise<StorageProviderItem[] | null> {
    const provider = getProvider(type);
    if (folder instanceof AbortSignal) {
      signal = folder;
      folder = 'root' as unknown as StorageProviderItem;
    }
    return getIsItemRoot(folder)
      ? provider.api.getRootChildren(signal)
      : provider.api.getFolderChildren(folder.id, folder.driveId, signal);
  }

  /**
   * Gets the recent items from the storage service API.
   * @param type - The provider type.
   * @param filter - The filter parameters.
   * @param signal - A signal used to cancel the request
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  getRecent(
    type: StorageProviderKey,
    filter: string[],
    signal: AbortSignal,
  ): Promise<StorageProviderItem[] | null> {
    if (filter instanceof AbortSignal) {
      signal = filter;
      filter = [];
    }
    return getProvider(type).api.getNewRecent(filter, signal);
  }

  /**
   * Gets the shared items from the storage service API.
   * @param type - The provider type.
   * @param folder - The folder to get the shared items for.
   * @param signal - A signal used to cancel the request
   */
  getShared(
    type: StorageProviderKey,
    folder: StorageProviderItem,
    signal: AbortSignal,
  ): Promise<StorageProviderItem[] | null> {
    if (folder instanceof AbortSignal) {
      signal = folder;
      folder = 'root' as unknown as StorageProviderItem;
    }

    if (getIsItemRoot(folder)) {
      return getProvider(type).api.getShared(signal);
    }

    return this.getChildren(type, folder, signal);
  }

  /**
   * Gets an item from the provider service API.
   * @param connection - The connection information for the item.
   * @param options - Additional options for the API call.
   * @returns - The item retrieved by ID.
   */
  getItem(
    connection: UnvalidatedConnection,
    options?: GetItemByIdOptions,
  ): Promise<StorageProviderItem | null> {
    const { type, itemId, driveId } = extractConnection(connection);
    return this.getItemById(type, itemId, driveId ?? '', options);
  }

  /**
   * Gets an item from the provider service API.
   * @param type - The provider type.
   * @param id - The ID of the item to retrieve.
   * @param driveId - The ID of the drive the item belongs to.
   * @param options - Additional options for the API call.
   * @returns - The item retrieved by ID.
   */
  getItemById(
    type: StorageProviderKey,
    id: StorageProviderItemId,
    driveId: StorageProviderDriveId,
    options?: GetItemByIdOptions,
  ): Promise<StorageProviderItem | null> {
    const { params, cache } = options ?? { cache: true, params: null };
    const paramStr = params ? JSON.stringify(params) : '';
    return getProvider(type).api.getItemById(id, driveId, cache, paramStr);
  }

  /**
   * Searches for an item using the storage service API.
   * @param query - The query to search for.
   * @param maxResults - The maximum number of results to return.
   * @param options - Additional options to pass to the API.
   */
  searchItem(
    type: StorageProviderKey,
    query: string,
    maxResults: number,
    options?: object,
  ): Promise<StorageProviderItem[] | null> {
    const optionsStr = options ? JSON.stringify(options) : '';
    return getProvider(type).api.searchItem(query, maxResults, optionsStr);
  }

  /**
   * Creates an item in the cloud storage provider's repository.
   * @param item - The item information used to create the connection.
   * @returns The connection information for the uploaded item.
   */
  createItem(
    type: StorageProviderKey,
    name: string,
  ): Promise<StorageProviderItemConnection> {
    return getProvider(type).api.createItemForConnection(name);
  }

  /**
   * Returns the remaining storage space for the current provider.
   */
  getRemainingStorageSpace(type: StorageProviderKey): Promise<number> {
    return getProvider(type).api.getRemainingStorageSpace();
  }

  renameItem(
    connection: UnvalidatedConnection,
    newName: string,
  ): Promise<string> {
    const extracted = extractConnection(connection);

    return getProvider(extracted.type).api.renameItem(
      extracted.itemId,
      extracted.driveId,
      newName,
    );
  }

  /**
   * Downloads the item from the provider.
   * @param type The item type.
   * @param id The item id.
   * @param driveId The drive id.
   * @returns A promise that resolves with the downloaded item blob.
   */
  async downloadItem(
    type: StorageProviderKey,
    id: StorageProviderItemId,
    driveId: StorageProviderDriveId,
  ): Promise<Blob> {
    const item = await this.getItemById(type, id, driveId, {
      cache : false,
      params: false,
    });
    if (!item) {
      throw new Error('Failed to download item: item not found.');
    }
    return getProvider(type).api.downloadItem(item);
  }

  /**
   * Gets the item from the intercepted state.
   * @param type - The provider type.
   * @param state - The intercepted state.
   * @returns The item from the intercepted state.
   */
  async getItemFromInterceptedState(
    type: StorageProviderKey,
    state: InterceptedOpenState,
  ): Promise<StorageProviderItem> {
    let item: StorageProviderItem;

    if (type === STORAGE_PROVIDER_KEYS.ONE_DRIVE) {
      item = (await msGraphService.api.getOneDriveItemFromShareURL(
        state.url,
      )) as StorageProviderItem;
    } else {
      const id = state.ids?.[0] ?? state.exportIds?.[0];
      if (!id) {
        throw new Error('Failed to get item from intercepted state');
      }
      item = await gDriveService.api.getItemById(id, '');
    }

    if (!item) {
      throw new Error('Failed to get item from intercepted state');
    }

    return item;
  }

  async duplicateItem(
    connection: UnvalidatedConnection,
    name?: string,
  ): Promise<StorageProviderItem> {
    const extracted = extractConnection(connection);
    const item = await this.getItem(extracted);
    if (!item) {
      throw new Error('Failed to get item from connection');
    }
    return getProvider(extracted.type).api.duplicateItem(item, name);
  }

  async getCheckoutUser(
    connection: UnvalidatedConnection,
  ): Promise<StorageProviderCheckoutUser | null> {
    const item = await this.getItem(connection);
    if (!item) {
      throw new Error('Failed to get item from connection');
    }
    return getProvider(connection.type).api.getCheckoutUser(item);
  }

  async verifyAutoSaveFolder(
    connection: UnvalidatedConnection,
  ): Promise<boolean> {
    try {
      const item = await this.getItem(connection);
      return Boolean(item);
    } catch {
      return false;
    }
  }
}

const client = new ProviderClient();

export default client;
