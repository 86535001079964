import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import {
  Checkbox,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mtb/ui';
import { useSessionStorage } from '../../../../../hooks';
import { useTranslation } from '../../../../../services/i18n';
import { useCloudExplorer, useCloudExplorerTable } from '../../../hooks';

type FileTypeFilter = { label: string; filterValues: string[] };
export const FILE_TYPE_FILTERS: FileTypeFilter[] = [
  { label: 'fileTypeMinitabProject', filterValues: ['.mpx', '.mpj'] },
  { label: 'fileTypeMinitabWorksheet', filterValues: ['.mwx', '.mtw'] },
  { label: 'fileTypeText', filterValues: ['.txt'] },
  { label: 'fileTypeCSV', filterValues: ['.csv'] },
  { label: 'fileTypeData', filterValues: ['.dat'] },
  { label: 'fileTypeSpreadsheet', filterValues: ['.xlsx'] },
  { label: 'fileTypeMinitabScript', filterValues: ['.mac', '.mtb'] },
  { label: 'fileTypeWorkspaceProject', filterValues: ['.wspx', '.qcpx'] },
  { label: 'fileTypeBrainstormProject', filterValues: ['.mbpx'] },
  { label: 'fileTypeMinitabReport', filterValues: ['.mdbx'] },
];

export const CloudExplorerFileTypeFilter = () => {
  const [t, i18n] = useTranslation();
  const { id, defaultFilter, disableCache } = useCloudExplorer();
  const { setFilter } = useCloudExplorerTable();
  const [selectedFilters, setSelectedFilters] = useSessionStorage<FileTypeFilter[]>({
    noun        : 'file-type-filter',
    id,
    initialValue: [],
    disableCache,
  });

  const checkboxOptions = useMemo(
    () =>
      FILE_TYPE_FILTERS.filter(({ filterValues }) =>
        defaultFilter.some((f) => filterValues.includes(f)),
      ),
    [defaultFilter],
  );

  const handleOnInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: FileTypeFilter[] }>) => {
      let newSelectedFilters = value;

      const { label: newValueLabel } = value[value.length - 1];
      if (selectedFilters.some((f) => f.label === newValueLabel)) {
        // If the filter is already selected, remove it
        newSelectedFilters = selectedFilters.filter((f) => f.label !== newValueLabel);
      }

      if (!newSelectedFilters.length) {
        // If the last filter was unselected, set the default filter
        setSelectedFilters([]);
        setFilter(defaultFilter);
        return;
      }

      setSelectedFilters(newSelectedFilters);
      setFilter(newSelectedFilters.flatMap(({ filterValues }) => filterValues));
    },
    [setFilter, defaultFilter, selectedFilters, setSelectedFilters],
  );

  const handleRenderOption = useCallback(
    (option: FileTypeFilter) => (
      <MenuItem
        key={option.label}
        // @ts-expect-error - TODO: @mtb/ui MenuItem should allow value to be an object
        value={option}>
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <Checkbox
          checked={selectedFilters.some((f: FileTypeFilter) => f.label === option.label)}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
          checkedIcon={<CheckBoxIcon />}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
          icon={<CheckBoxOutlineBlankIcon />} />
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <ListItemText>{t(`connection.${option.label}`)}</ListItemText>
      </MenuItem>
    ),
    [t, selectedFilters],
  );

  const handleRenderValue = useCallback(
    (selectedOptions: FileTypeFilter[]) => (
      <Typography
        color={selectedOptions.length === 0 ? 'text.disabled' : 'text.primary'}
        sx={{ display: 'inline-block' }}>
        {selectedOptions.length === 0 && t('connection.type')}
        {selectedOptions.length === 1 && i18n.localeSettings.listFormatter.format(selectedOptions[0].filterValues)}
        {selectedOptions.length > 1 && t('connection.moreSelected', { more: selectedOptions.length })}
      </Typography>
    ),
    [t, i18n.localeSettings.listFormatter],
  );

  return (
    <TextField
      data-testid="file-type-filter"
      // @ts-expect-error-next-line - Type 'true' is not assignable to type 'false'.ts(2322)
      select
      // @ts-expect-error-next-line - Type '{ multiple: true; displayEmpty: true; value: any; renderValue: (selectedOptions: FileTypeFilter[]) => JSX.Element; onChange: ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => void; }' is not assignable to type 'undefined'.ts(2322)
      SelectProps={{
        multiple    : true,
        displayEmpty: true,
        value       : selectedFilters,
        renderValue : handleRenderValue,
        onChange    : handleOnInputChange,
      }}
      sx={{
        width          : '35%',
        minWidth       : 150,
        backgroundColor: 'background.50',
      }}>
      {/* @ts-expect-error-next-line - Type 'Element[]' is not assignable to type 'undefined'.ts(2322) */}
      {checkboxOptions.map((option) => handleRenderOption(option))}
    </TextField>
  );
};
